export const POLL_BASE_FRAGMENT = `
  fragment pollBaseFragment on Poll {
    uid
    title
    realTimeAnswers
    startTime
    endTime
    startTimestamp
    endTimestamp
    session {
      uid
      parentSession {
         uid
      }
    }
  }
`;
